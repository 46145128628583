<template>
  <div v-if="isLoginRoute" class="d-flex justify-content-center align-items-center">
    <Card class="wmin-350 wmin-md-450" :with-footer="false">
      <template v-slot:default>
        <div class="text-center mb-3">
          <Logo class="mb-3 mt-1" :is-ico="true"/>
        </div>
        <div class="login-form-container" v-if="!user.isLoaded()">
          <div v-if="isUserPasswordLogin">
            <div class="form-group form-group-feedback form-group-feedback-left">
              <TextInput @input="setEmail" :reference="user" :value="user.getEmail()"
                         :placeholder="$t('general.login.username')" v-bind:class="v$.user.email.$error && 'error'"/>
              <div class="form-control-feedback">
                <i class="icon-user text-muted"></i>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.user.email.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>

            <div class="form-group form-group-feedback form-group-feedback-left"
                 v-bind:class="v$.user.password.$error && 'form-group-error'">
              <TextInput @input="setPassword" :reference="user" :value="user.getPassword()" type="password"
                         :placeholder="$t('general.login.password')" v-bind:class="v$.user.password.$error && 'error'"/>
              <div class="form-control-feedback">
                <i class="icon-lock2 text-muted"></i>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.user.password.$errors" :key="index">
                <span class="input-error text-danger text-wrap">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="form-group form-group-feedback form-group-feedback-left"
                 v-bind:class="v$.user.loginToken.$error && 'form-group-error'">
              <TextInput @input="setLoginToken" :reference="user" :value="user.getLoginToken()" type="password"
                         :placeholder="$t('general.login.token')" v-bind:class="v$.user.loginToken.$error && 'error'"/>
              <div class="form-control-feedback">
                <i class="icon-lock2 text-muted"></i>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.user.loginToken.$error" :key="index">
                <span class="input-error text-danger text-wrap">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
          </div>
          <div v-show="canShowNotification" class="form-group">
            <NotificationAlert :notifications="notification"/>
          </div>
          <div class="form-group">
            <Button v-bind:disabled="!user.isChanged() || !isFormReady" @click="login" type="confirm"
                    :name="$t('general.login.buttons.signin')" class="w-100"/>
          </div>

          <div v-if="isUserPasswordLogin" class="text-center">
            <router-link :to="{name: 'general.breadcrumb.users.forgot'}">
              {{ $t('general.login.buttons.forgot') }}
            </router-link>
          </div>
        </div>
        <div v-else class="text-center">
          <i class="icon-check icon-4x text-verde"></i><h4 class="align-self-center m-0 p-0 ">
          {{ $t('general.login.post') }}</h4>
        </div>
      </template>
    </Card>
  </div>
  <router-view></router-view>
</template>

<script>
import Card from "@/components/elements/Card";
import Logo from "@/components/elements/Logo";
import TextInput from "@/components/elements/TextInput";
import Button from "@/components/elements/Button";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import User from "@/entities/User";
import {AuthorizationMixin} from "@/mixins/GeneralMixin";
import useVuelidate from '@vuelidate/core'
import {required, email} from '@vuelidate/validators'
import {ValidationsMixin, NotificationsMixin} from "@/mixins/GeneralMixin";
import NotificationAlert from "@/components/elements/NotificationAlert";

export default {
  setup() {
    return {v$: useVuelidate()}
  },
  name: "UserLogin",
  components: {NotificationAlert, Button, TextInput, Logo, Card},
  mixins: [LoadingModalMixin, AuthorizationMixin, ValidationsMixin, NotificationsMixin],
  validations: {
    user: {
      email: {
        required: required,
        email: email,
      },
      password: {
        required,
      },
      loginToken: {
        required,
      }
    },
  },
  computed: {
    isLoginRoute: function () {
      return 'general.breadcrumb.users.login' === this.$router.currentRoute.value.name;
    },
    isFormReady: function () {
      return !(this.v$.user.email.$error || this.v$.user.password.$error || (this.isUserPasswordLogin && this.v$.user.loginToken.$error));
    },
    isUserPasswordLogin: function () {
      return this.$route.query.token !== 'yes';
    },
  },
  data: function () {
    return {
      user: new User(),
    }
  },
  mounted() {
    this.$cookie.removeCookie('user_token');
    if (this.$route.query.expired) {
      this.addWarningNotification('general.login.expired');
    }
  },
  methods: {
    resetForm: function ()
    {
      this.setEmail(this.user, "");
      this.setPassword(this.user, "");
      this.setLoginToken(this.user, "");
      this.v$.user.email.$reset();
      this.v$.user.password.$reset();
      this.v$.user.loginToken.$reset();
    },
    setPassword: function (user, value) {
      user.setPassword(value);
      this.v$.user.password.$touch();
    },
    setEmail: function (user, value) {
      user.setEmail(value);
      this.v$.user.email.$touch();
    },
    setLoginToken: function (user, value) {
      user.setLoginToken(value);
      this.v$.user.loginToken.$touch();
    },
    login: function () {
      let that = this;
      let data = {};

      if (!this.isUserPasswordLogin) {
        this.v$.user.loginToken.$touch();
      } else {
        this.v$.user.password.$touch();
        this.v$.user.email.$touch();
      }
      if (!this.isFormReady) {
        return;
      }

      if (!this.isUserPasswordLogin) {
        data = {
          token: this.user.getLoginToken(),
        };
      } else {
        data = {
          email: this.user.getEmail(),
          password: this.user.getPassword(),
        };
      }

      this.$store.commit("skipResponseCheckForStatusCode", 403);
      this.resetNotifications();
      that.queueJob();
      this.axios.post(this.$store.state.config.getTokenLoginUri(), data).then(
          response => {
            let content = response.data;
            that.user.setLoaded(true);
            that.timeout = setTimeout(function () {
              if (!that.isLoggedInUserAndValid) {
                that.addWarningNotification('general.login.expired');
                that.user = new User();
              }
            }, 3000)
            if (that.hydrateLoggedInUserFromToken(content.data)) {
              if (that.$store.state.afterLoginRedirectTo) {
                let redirectTo = that.$store.state.afterLoginRedirectTo;
                that.$store.commit('afterLoginRedirectTo', null);
                that.$router.push({name: redirectTo.name, params: redirectTo.params});
                return;
              }

              that.$router.push({name: "general.breadcrumb.dashboard"});
            } else {
              that.user = new User();
              that.addWarningNotification('general.login.failed');
            }
          }
      ).catch(
          error => {
            that.resetGlobalNotifications();
            this.resetForm();
            that.addWarningNotification('general.login.failed');
            that.user = new User();
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    }
  }
}
</script>

<style scoped>
img {
  height: 4rem;
}

.login-form-container {
  min-width: 17rem;
}
</style>